import '../sass/main.scss';

import LazyLoad from 'vanilla-lazyload';
import $ from 'jquery';
window.$ = window.jQuery = $;
import 'slick-carousel';
import interact from 'interactjs';

var siteGlobal = {
  initReady: function () {
  },
  initLoad: function () {
    this.customSelect();
  	this.lazyLoad();
    this.menuInit();
    this.serviziSlider();
    this.homeDistSlider();
    this.confrontoPrezzi();
    this.letturaBolletta();
    this.formCheckboxes();
    this.risparmioInteractions();
    this.simulazInit();
    this.scrollToTop();
    this.partSlider();
    this.faqInit();
    this.switchInit();
  },
  initScroll: function(){
  },
  lazyLoad: function(){
    var ll = new LazyLoad({
        elements_selector: '.lazy',
        use_native: true
    })
  },
  menuInit: function(){
    $('.header__hamburger').on('click', function(){
      $('.header__hamburger, .header').toggleClass('opened');
      $('.header__nav').fadeToggle(200);
    })

    $('.menu-item-has-children > a').addClass('no-smoothState');
    
    $('.menu-item-has-children').on('click', function(event){
      event.preventDefault();
      $(this).toggleClass('active');
      var sub = $(this).find('.sub-menu');
      sub.slideToggle(200);
    })
  },
  serviziSlider: function(){
    $('.analisi__slider').not('.slick-initialized').slick({
      slidesToShow: 1,
      fade: true,
      slidesToScroll: 1,
      arrows: true,
      dots: true,
      autoplay: true,
      autoplaySpeed: 4000,
      lazyLoad: 'progressive',
      speed: 350,
      infinite: true
    });
  },
  homeDistSlider: function(){
    if ($(window).outerWidth(true) < 992) {
      $('.homeDist__list, .homePrezzo__list').slick({
        slidesToShow: 1,
        fade: true,
        slidesToScroll: 1,
        arrows: false,
        dots: true,
        autoplay: true,
        autoplaySpeed: 4000,
        lazyLoad: 'progressive',
        speed: 350,
        infinite: true
      });
    }
  },
  confrontoPrezzi: function(){
    /* Posiziono centralmente le label dei prezzi sulle colonnine del grafico */
    $('.tab__cifra').each(function(){
      var parH = $(this).parent('span').outerHeight(true);
      $(this).css('width', parH);
    })


    /* Imposto altezza contenuti dentro tab */
    function changeTabHeight(){
      var aciveTabH = $('.tab.active').outerHeight(true);
      $('.confronto__tableInner').css('height',aciveTabH);
    }
    changeTabHeight();

    $('.sel:first-child, .legBol:first-child').addClass('active');

    $('.sel').on('click', function(){
      var trig = $(this).data('trig');
      $('.sel, .confronto__table, .legBol').removeClass('active');
      $(this).addClass('active');

      setTimeout(function(){
        $('[data-table="'+trig+'"]').addClass('active');
        changeTabHeight();
      }, 600)
    })
  },
  letturaBolletta: function(){
    $('.legBol__plusWrap .plus-open').on('click', function(){
      var com = $(this).parents('.legBol__plusWrap').data('comic');
      $(this).addClass('closed');
      $(this).parent().siblings('.legBol__comic').addClass('active');
      $('[data-row="'+com+'"]').find('.legBol__row').addClass('active');
    })
    $('.legBol__plusWrap .plus-close').on('click', function(){
      var com = $(this).parents('.legBol__plusWrap').data('comic');
      $(this).parent().removeClass('active');
      $('[data-row="'+com+'"]').find('.legBol__row').removeClass('active');
      $(this).parent().siblings().find('.plus-open').removeClass('closed');
    })
  },
  formCheckboxes: function(){
    $('.tipologia-cliente .wpcf7-list-item').on('click', function(){
      $('.wpcf7-list-item').removeClass('first');
      $(this).addClass('first');
    });

    if ($('.tipo').length) {
      var type = $('.tipo').data('tipo');
      if(type){
        $('.wpcf7-list-item').removeClass('first');
        $('.wpcf7-list-item').each(function(){
          var value = $(this).find('[type="radio"]').val();
          
          if( value == type ){
            $('[value="'+type+'"]').parent().addClass('first');
          }
        })
      }
    }
  },
  customSelect: function(){
    $('.wpcf7 select').each(function(){
        var $this = $(this), 
          id = $(this).attr('id'),
          numberOfOptions = $(this).children('option').length;
          // classList = $(this).attr('class').split(',');;
      
        $this.addClass('select-hidden'); 
        $this.wrap('<div class="select '+id+'"></div>');
        $this.after('<div class="select-styled"></div>');

        var $styledSelect = $this.next('div.select-styled');
        $styledSelect.text($this.children('option').eq(0).text());
      
        var $list = $('<ul />', {
            // 'class': 'select-options ' + classList
            'class': 'select-options '
        }).insertAfter($styledSelect);
      
        for (var i = 0; i < numberOfOptions; i++) {
            $('<li />', {
                text: $this.children('option').eq(i).text(),
                rel: $this.children('option').eq(i).val()
            }).appendTo($list);
        }
      
        var $listItems = $list.children('li');
      
        $styledSelect.on('click', function(e) {
            e.stopPropagation();
            $(this).toggleClass('active').next('ul.select-options').slideToggle(150);
            $('div.select-styled.active').not(this).each(function(){
                $(this).removeClass('active').next('ul.select-options').hide();
            });
        });
      
        $listItems.on('click', function(e) {
            e.stopPropagation();
            $styledSelect.text($(this).text()).removeClass('active');
            $this.val($(this).attr('rel'));
            $list.hide();
            $('.select-styled').addClass('sel-act')
        });

    });
  },
  risparmioInteractions: function(){
    const slider = interact('#drag');
    slider.draggable({
      origin: 'self',
      inertia: true,
      modifiers: [
        interact.modifiers.restrict({
          restriction: 'self'
        })
      ],
      listeners: {
        move (event) {
          const sliderWidth = interact.getElementRect(event.target).width
          const value = event.pageX / sliderWidth
          const pad = value * 100
          const zoomPercentage = (pad / 100) + 1;
          const reverseZoomPercentage = 3 - ((pad / 100) + 1);

          event.target.setAttribute('data-value', value.toFixed(2))

          /* Drag bar - muovo icona e barra */
          
          $('.risparmio__dragBarIcon').css('left', pad+'%');
          $('.risparmio__dragBarInner').css('width', pad+'%');

          /* Colonne gruppo - prezzo - risparmio - muovo barra */

          $('.risparmio__col--gruppo .risparmio__barInner, .risparmio__col--risparmio .risparmio__barInner, .risparmio__col--gruppo .risparmio__iconWrap, .risparmio__col--risparmio .risparmio__iconWrap').css('top', pad+'%');

          $('.risparmio__col--prezzi .risparmio__barInner, .risparmio__col--prezzi .risparmio__iconWrap').css('top', (100 - pad)+'%');

          /* Colonne gruppo - prezzo - risparmio - muovo icona */

            $('.risparmio__col--prezzi .risparmio__iconWrap').css('transform','scale('+zoomPercentage+') translate(-50%, 0)');

            $('.risparmio__col--gruppo .risparmio__iconWrap, .risparmio__col--risparmio .risparmio__iconWrap').css('transform','scale('+reverseZoomPercentage+') translate(-50%, 0)');

           $('.risparmio__col--gruppo .risparmio__iconWrap, .risparmio__col--risparmio .risparmio__iconWrap').css('top', pad+'%');

           // $('.risparmio__col--prezzi .risparmio__iconWrap').css('bottom', pad+'%');
        }
      }
    })
  },
  simulazInit: function(){
    if ($('[name="privato-azienda"]').length) {
      $('[name="privato-azienda"]').find('option:first-child').text('Sono un');
      $('.select-styled').text('Sono un');
    }
  },
  scrollToTop: function(){
    $('#totop').on('click', function(){
      window.scrollTo({top: 0, behavior: 'smooth'});
    })
  },
  partSlider: function(){
      $('.part__slider').slick({
        slidesToShow: 1,
        fade: true,
        slidesToScroll: 1,
        arrows: true,
        dots: true,
        autoplay: true,
        autoplaySpeed: 4000,
        lazyLoad: 'progressive',
        speed: 350,
        infinite: true
      });
  },
  faqInit: function(){
    $('.faq__trig').on('click', function(){
      $(this).toggleClass('opened');
      $(this).siblings('.faq__text').slideToggle(200);
    })
  },
  switchInit: function(){
    $('.switch__icon--gas').on('click', function(){
      $('.switch__ball').removeClass('switch__ball--el').addClass('switch__ball--gas');
      $('.switch__icon--el').removeClass('active');
      $('.switch__icon--gas').addClass('active');
    })
    $('.switch__icon--el').on('click', function(){
      $('.switch__ball').removeClass('switch__ball--gas').addClass('switch__ball--el');
      $('.switch__icon--gas').removeClass('active');
      $('.switch__icon--el').addClass('active');
    })
  },
  rebuildAllEvents: function(){
    this.initLoad();
  },
};

global.siteGlobal = siteGlobal;


(function () {
  siteGlobal.initReady();
  $(window).on('load', function() {
    $('#preloader').addClass('loaded');
    siteGlobal.initLoad();
  });

  $(window).on('scroll', function(){
    siteGlobal.initScroll();
  })
}());